<template>
  <div>
    <iq-card>
      <template v-slot:body>
        <div class="user-post-data p-3">
          <div class="d-flex flex-wrap">
            <div class="media-support-info mt-2">
              <b-form class="comment-text align-items-center mt-3" action="javascript:void(0);">
                <b-form-select plain v-model="category" :options="userState.role === 'admin' ? adminOptions : options" />
                <div :style="{padding: '10px'}" />
                <b-form-input type="text" v-model="title" class="rounded" placeholder="제목을 입력해주세요." />
              </b-form>
            </div>
          </div>
        </div>
      </template>
      <div class="user-post">
        <div class="output ql-snow">
          <SmartEditor :title="title" :category="category" :comment_allow="true" />
        </div>
      </div>
    </iq-card>
  </div>
</template>
<script>
import { core } from '../../config/pluginInit'
import SmartEditor from './Form/SmartEditor.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'DashboardPost',
  components: {
    SmartEditor
  },
  data () {
    return {
      title: '',
      category: '',
      options: [
        { html: '<b>카테고리를 선택해주세요.</b>', value: '', disabled: true }
      ],
      adminOptions: [
        { html: '<b>카테고리를 선택해주세요.</b>', value: '', disabled: true }
        // { text: '공지사항', value: 'notice' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      userState: 'Auth/userState',
      categoryState: 'Category/categoryState'
    })
  },
  methods: {
    setCategory () {
      for (let i = 0; i < this.categoryState.length; i++) {
        const category = {
          html: `<b>---- ${this.categoryState[i].category_name} ----</b>`,
          value: '',
          disabled: true
        }
        this.options.push(category)
        this.adminOptions.push(category)
        if (this.categoryState[i].subcategory.length !== 0) {
          for (let j = 0; j < this.categoryState[i].subcategory.length; j++) {
            const item = {
              text: this.categoryState[i].subcategory[j].category_name,
              value: this.categoryState[i].subcategory[j].category_name
            }
            this.options.push(item)
            this.adminOptions.push(item)
          }
        }
      }
    }
  },
  mounted () {
    core.index()
    this.setCategory()
  }
}
</script>
<style scoped>
.progress-bar-info {
  background-color: #0cd2e3 !important;
}

.progress-bar-success {
  background-color: #1be1b3 !important;
}

.progress-bar-warn {
  background-color: #f4b72a !important;
}

.progress-bar-pink {
  background-color: #fd6c9e !important;
}
</style>
